:root {
  --red: rgb(255, 96, 92);
  --yellow: rgb(255, 189, 68);
  --green: rgb(0, 202, 78);
  --bg: rgb(41, 45, 42);
  --bg-header: rgb(34, 33, 33);
  --bg-dark: rgb(21, 23, 31);
  --radius: 5px;
  --editor-width: 750px;
  --editor-height: 500px;
}

@media screen and (max-width: 1500px) {
  .editor {
    width: 600px !important;
    height: 450px !important;
    transition: width 2s height 2s;
  }
}

@media screen and (max-width: 1200px) {
  .editor {
    width: 550px !important;
    height: 400px !important;
    transition: width 2s height 2s;
  }
}

@media screen and (max-width: 600px) {
  .editor {
    width: 450px !important;
    height: 300px !important;
    transition: width 2s height 2s;
  }

  .tabs {
    left: 34px !important;
    width: calc(100% - 34px) !important;
  }

  .code {
    overflow: scroll;
  }
}

.editor {
  position: relative;
  width: var(--editor-width);
  height: var(--editor-height);
  background-color: var(--bg);
  border-radius: var(--radius);
}

.editor > .header {
  position: absolute;
  background-color: var(--bg);
  width: 100%;
  height: 20px;
  top: 0;
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.editor > .tabs {
  position: absolute;
  background-color: var(--bg-dark);
  width: calc(100% - 40px);
  height: 33px;
  top: 2px;
  left: 40px;
  margin-top: 20px;
}

.editor > .tabs > .tab {
  width: 110px;
  padding: 5px;
  display: flex;
  align-items: center;
  border-right: 1px solid black;
  border-top: 2px solid yellow;
  cursor: pointer;
}

.editor > .tabs > .tab > svg {
  color: rgba(160, 178, 201, 0.5);
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.editor > .tabs > .tab > span {
  font-size: 0.9rem !important;
  color: rgba(160, 178, 201, 0.5);
}

.editor > .header > .window-btns {
  background-color: var(--bg-header);
  display: flex;
  align-items: center;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.editor > .header > .window-btns > .btn {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.btn.btn-close {
  background-color: var(--red);
}

.btn.btn-minimize {
  background-color: var(--yellow);
}

.btn.btn-maximize {
  background-color: var(--green);
}

.editor > .header > .window-btns > .btn:last-child {
  margin-right: 0px;
}

.editor > div > .side-btns {
  width: 40px;
  height: 100%;
  background-color: var(--bg-dark);
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
}

.editor > div > .side-btns > .side-btns-wrapper {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  height: 100%;
  display: flex;
}

.editor > div > .side-btns > .side-btns-wrapper > .side-btns-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 20px;
  color: rgba(160, 178, 201, 0.5);
  cursor: pointer;
  border-bottom-left-radius: var(--radius);
}

.editor > div > .side-btns > .side-btns-wrapper > .side-btns-icon:last-child {
  margin-bottom: 0px;
}

.editor > div > .code {
  position: relative;
  margin-top: 60px;
  font-size: 0.8rem;
  font-family: "Consolas";
  text-align: initial;
}

/* remove this once we find a good theme */
.hljs {
  background: var(--bg) !important;
}
