.card {
    border-radius: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 15px;
    background-color: var(--secondary);
}

.card > a {
    /* don't underline the anchor */
    text-decoration: none;
    color: var(--tertiary);
    border-bottom: 2.5px solid var(--harvest-gold);
    cursor: pointer;
}

.card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.card-image {
    width: 100%;
    margin-left: auto;
}

.footer-anchor {
    margin-right: 10px;
}