:root {
  --jet: #2C2F33;
  --eerie-black: #212223;
  --platinum: #E0E0E0;
  --harvest-gold: #ECA400;
  --timberwolf: #DBD9D9;
  --silver: #B5B5B5;

  --primary: var(--jet);
  --bg-primary: var(--eerie-black);
  --secondary: var(--eerie-black);
  --tertiary: var(--platinum);
  --warning: var(--harvest-gold);
}

body {
  background-color: var(--primary);
}

h1, h2, h3, h4, h5, h6, p {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  color: var(--tertiary);
}

span {
  color: var(--tertiary);
}

#root {
  margin-left: 16px;
  margin-right: 16px;
}

@media screen and (min-width: 1025px) {
  #root {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

@media screen and (min-width: 1025px) {
  .xl-flex {
    display: flex;
  }
}

.web-content {
  padding-bottom: 50px;
}

.section {
  margin-top: 3rem;
}

.flex-title-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  background-color: var(--light-dark);
}

.col {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.flex-external-icons-container {
  display: flex;
  justify-content: center;
}

.flex-external-icons-container > .external-icon {
  cursor: pointer;
  margin: 10px;
  margin-top: 20px;
}

.flex-external-icons-container > .external-icon > img {
  width: 32px;
  height: 32px;
}

.title {
  font-weight: 900;
  font-size: 50px !important;
  line-height: 50px;
}